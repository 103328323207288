body {
    background-color: #e9ebf0;
    font-family: Helvetica, Arial;
}

.header {
    background-color: #0093d5;
    padding: 10px;
}

.header h1 {
    color: #fff;
}

.wrapper {
    margin-top: 50px;
    background-color: #fff;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
}

.wrapper p {
    font-style: italic;
    color: #666;
}

.wrapper label {
    display: block;
}

.wrapper input {
    padding: 3px 6px;
}

.amount-input {
    margin-bottom: 10px;
}

.wrapper select {
    display: block;
}

.button {
    margin: 10px 2px;
    padding: 5px 20px;
    border-radius: 8px;
    background-color: #0093d5;
    border: none;
    color: #fff;
    text-align: center;
    display: inline-block;
    font-size: small;
}

.button:hover {
    cursor: pointer;
    opacity: 0.75;
}

.wrapper hr {
    margin: 40px auto;
}

@media only screen and (min-width: 1400px) {
    .wrapper {
        width: 50%;
    }

    .wrapper input {
        width: 600px;
    }
}

@media only screen and (max-width: 1399px) and (min-width: 750px) {
    .wrapper {
        width: 75%;
    }

    .wrapper input {
        width: 400px;
    }
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
