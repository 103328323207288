.dialog-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-item {
    margin-bottom: 20px;
    padding: 0px 10px;
    width: fill;
}